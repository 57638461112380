$cm-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@import 'node_modules/@sfg-cm/cm-api-theme/scss/scm-theme-bootstrap.scss';

.sidebar {
	width: 230px;
}

.main-article {
	width: 100%;
	margin-left: 1rem !important;
}

.content-text {
	font-size: 1rem;
	font-family: 'Assistant', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.minimum-page-height {
	min-height: 600px;
}


@mixin legacy-company-theme($color, $darkcolor, $lightcolor, $shadedcolor) {
	
	table.PolicyTable > tbody > tr:nth-of-type(odd) {
	  background-color: rgba($shadedcolor, 50%);
	}
    
	.announcements {
	   @extend .alert; 
		border-radius: 0.5rem;
		p, li {
			font-size: 0.875rem;
		}
		ul {
			padding-inline-start: 2rem;
		}
		li {
			padding-left: 0;
			margin-bottom: 0.25rem;
		}
		h3 {
			font-size: 1.3rem;
			font-weight: 500;
		}
		
		width: 17rem;
	}
	
	div.PolicySection {
		color: $color !important;
        font-size: 1rem !important;
        font-weight: 700;
        padding-top: 0.5rem;
	}

	.btn-company a {
		color: #ffffff;
	}

	.inbox-view .label-company {
		padding-right: 1em;
		width: 7rem;
		text-align: right;
	}
	
	td.label-company {
		padding-right: 1em;
    }
    
    .sitemap {
        column-count: 2;
        column-gap: 2rem;
        color: $color;
        h5 {
            margin-top: 1rem;
            margin-bottom: 0.25rem;
            font-size: 1rem;
            font-weight: 700;
        }
        h5:nth-child(1) {
            margin-top: 0rem !important;   
        }
    }

	
}

.midland {
    @include legacy-company-theme($midland-primary, $midland-dark, $midland-light, $midland-shaded)
}

.nac {
    @include legacy-company-theme($nacolah-primary, $nacolah-dark, $nacolah-light, $nacolah-shaded)
}

.p-logo {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.inbox-message {
	background-color: $sammons-gray-100;
	min-height: 200px;
	padding: 2px;
}

.inbox div.label {
	margin-right: 1rem;
}

#loginForm td {
	padding-bottom: 1rem;
	padding-right: 0.25rem;
}

.policyValues td {
	padding: 4px 2px 4px 2px;
}

.formsearch-control {
	margin-top: 0.025rem !important;
	height: 2rem !important;
	vertical-align: top !important;
}

div.code {
	font-family: $font-family-monospace;
	font-size: 9pt;
	word-break: break-word;
}

/* Page Specific Classes */

/* Search */

.pagerHide {
	visibility: hidden;
	display: none;
}

.pagerShow {
	visibility: visible;
	display: block;
}

.pagerBlock ul {
	list-style: none;
	padding-left: 5px;
	padding-right: 5px;
	margin: 0;
	line-height: 125%;
}

.pagerBlock ul li {
	margin-top: 5px;
	margin-bottom: 5px;
	list-style: none;
}

.pagerFooter {
	margin-top: 5px;
	font-weight: 700;
}

.midland .btn-company a:hover {
	color: $midland-primary !important;
	text-decoration: none;
}

.midland a.company-link-highlight:hover {
	background-color: $midland-shaded !important;
	text-decoration: none;
}

.nac .btn-company a:hover {
	color: $nacolah-primary !important;
	text-decoration: none;
}

.scm .btn-company a:hover {
	color: $sammons-primary !important;
	text-decoration: none;
}

.company-links a {
	text-decoration: underline !important;
}

article.content-text h1 {
	font-size: 2.0rem;
}
